import { Component, OnInit } from '@angular/core';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
  export class PortfolioComponent implements OnInit {
    portfilio_list = [
    { 
      id: 1,
      name: 'Shlltrr' ,
      country: 'Kuwait',
      subtitle: 'Shlltrr Website is a platform to find or rent Property',
      website: '',
      type: 'group1 group2 group3',
      photo: 'assets/images/portfolio/thumb/1.jpg',
      morephotos: [
        'assets/images/portfolio/1.jpg',
        'assets/images/portfolio/project1/1.png',
        'assets/images/portfolio/project1/2.png',
        'assets/images/portfolio/project1/3.png',
        'assets/images/portfolio/project1/4.png',
        'assets/images/portfolio/project1/5.png'
      ],
      details: 'Shlltrr porject is a PHP Website , Full Stack Web development ,Both front end and back end coding , With huge and enormous features like Multi-languages, Matching, Online Chat, Profiles, Notification, Emails, Filters and so on ..' 
      },
      { 
        id: 2, 
        name: 'Siter School Mangment System  ' ,
        country: 'KSA',
        subtitle: 'School Mangment System and ERP ,HR system for schools',
        website: '',
        type: 'group1 group2 group3',
        photo: 'assets/images/portfolio/thumb/20.png',
        morephotos: [
          'assets/images/portfolio/20.png',
          'assets/images/portfolio/project2/1.png',
          'assets/images/portfolio/project2/2.png',
          'assets/images/portfolio/project2/3.png',
          'assets/images/portfolio/project2/4.png',
          'assets/images/portfolio/project2/5.png'
        ],
        details: 'PHP Codeigniter Framework System with AJAX ,Full Stack development ,Both front end and back end coding .. Includes all school system and ERP features.. ' 
        },
        { 
          id: 3, 
          name: 'Prsteej' ,
          country: 'Jordan',
          subtitle: 'Prsteej is an online shop,to sell different categories of clothing at cheap prices',
          website: '',
          type: 'group1 group2 group3',
          photo: 'assets/images/portfolio/thumb/4.png',
          morephotos: [
            'assets/images/portfolio/4.png',
            'assets/images/portfolio/project3/1.png',
            'assets/images/portfolio/project3/2.png',
            'assets/images/portfolio/project3/9.png',
            'assets/images/portfolio/project3/10.png',
            'assets/images/portfolio/project3/3.png',
            
            'assets/images/portfolio/project3/4.png',
            'assets/images/portfolio/project3/5.png',
            'assets/images/portfolio/project3/6.png',
            'assets/images/portfolio/project3/7.png',
            'assets/images/portfolio/project3/8.png'
           
          ],
          details: 'PHP Online shop ,Full Stack development ,Both front end and back end coding .. Includes huge features and admin dashboard .. ' 
          } ,
          { 
            id: 4, 
            name: 'Sahrh' ,
            country: 'KSA',
            subtitle: 'Sahrh is a multi Vendor booking online system ',
            website: '',
            type: 'group1 group2 group3',
            photo: 'assets/images/portfolio/thumb/19.png',
            morephotos: [
              'assets/images/portfolio/19.png',
              'assets/images/portfolio/project4/1.png',
              'assets/images/portfolio/project4/2.png',
              'assets/images/portfolio/project4/3.png',
              'assets/images/portfolio/project4/4.png',
              'assets/images/portfolio/project4/5.png',
              'assets/images/portfolio/project4/12.png',

              'assets/images/portfolio/project4/6.png',
              'assets/images/portfolio/project4/7.png',
              'assets/images/portfolio/project4/8.png',
              'assets/images/portfolio/project4/9.png',
              'assets/images/portfolio/project4/11.jpg',
              'assets/images/portfolio/project4/13.png',
              'assets/images/portfolio/project4/14.png'
            ],
            details: 'Multi-vendors Booking online system , with backend (Dashbord) to control the website , and frontend,its a PHP Full stack development website , with huge features like booking system, calender booking , vendors account ,admin, customers profiles , chat ,sell,buy ,payment and so on ..' 
            },
            { 
              id: 5, 
              name: 'Fanan' ,
              country: 'Jordan',
              subtitle: 'Multi Vendor / Marketplace online store',
              website: '',
              type: 'group1 group2 group3',
              photo: 'assets/images/portfolio/thumb/21.png',
              morephotos: [
                'assets/images/portfolio/21.png',
                'assets/images/portfolio/project5/5.png',

                'assets/images/portfolio/project5/1.png',
                'assets/images/portfolio/project5/2.png',
                'assets/images/portfolio/project5/3.png',
                'assets/images/portfolio/project5/4.png',
                'assets/images/portfolio/project5/6.png',
                'assets/images/portfolio/project5/7.png',
                'assets/images/portfolio/project5/8.png'
              ],
              details: 'PHP Online shop ,Full Stack development ,Both front end and back end coding .. Includes huge features and admin dashboard .. ' 
              },
              { 
                id: 6, 
                name: 'Sintafic Resarher' ,
                country: 'KSA',
                subtitle: 'Sintafic Resarher is a online Freelance Marketplace',
                website: '',
                type: 'group1 group2 group3',
                photo: 'assets/images/portfolio/thumb/6.png',
                morephotos: [
                  'assets/images/portfolio/6.png',
                  'assets/images/portfolio/project6/5.png',
                  'assets/images/portfolio/project6/1.png',
                  'assets/images/portfolio/project6/2.png',
                  'assets/images/portfolio/project6/3.png',
                  'assets/images/portfolio/project6/4.png',
                  'assets/images/portfolio/project6/6.png',
                  'assets/images/portfolio/project6/7.png',
                  'assets/images/portfolio/project6/8.png',
                  'assets/images/portfolio/project6/9.png'

                ],
                details: 'PHP Online Freelance Marketplace  , Full Stack development ,Both front end and back(Dashbord) end coding .. Includes huge features and admin dashboard .. ' 
                },
                { 
                  id: 7, 
                  name: 'Zaer' ,
                  country: 'KSA',
                  subtitle: 'Zaer KSA Transportation Services',
                  website: '',
                  type: 'group1 group2 group3',
                  photo: 'assets/images/portfolio/thumb/7.png',
                  morephotos: [
                    'assets/images/portfolio/7.png',
                    'assets/images/portfolio/project7/5.png',
                    'assets/images/portfolio/project7/1.png',
                    'assets/images/portfolio/project7/2.png',
                    'assets/images/portfolio/project7/3.png',
                    'assets/images/portfolio/project7/4.png',
                    'assets/images/portfolio/project7/6.png',
                    'assets/images/portfolio/project7/7.png' 
                  ],
                  details: 'Zaer is a WordPress Website with custom code design and lot of features including Multi-languages, chat , custom design ..' 
                  },
                  { 
                    id: 8, 
                    name: 'Sommaq' ,
                    country: 'KSA',
                    subtitle: 'Sommaq is a MarketPlace to buy and sell all kind of food ',
                    website: '',
                    type: 'group1 group2 group3',
                    photo: 'assets/images/portfolio/thumb/22.png',
                    morephotos: [
                      'assets/images/portfolio/22.png',
                      'assets/images/portfolio/project8/5.png',
                      'assets/images/portfolio/project8/1.png',
                      'assets/images/portfolio/project8/2.png',
                      'assets/images/portfolio/project8/3.png',
                      'assets/images/portfolio/project8/4.png',
                  
                    ],
                    details: 'Full Stack development forntend and backend ,Multi vendor, Multi Languages and many more features', 
                    },
                    { 
                      id: 9, 
                      name: 'Qessat Najah' ,
                      country: 'KSA',
                      subtitle: ' ',
                      website: '',
                      type: 'group1 group2 group3',
                      photo: 'assets/images/portfolio/thumb/25.png',
                      morephotos: [
                        'assets/images/portfolio/25.png',
                        'assets/images/portfolio/project9/5.png',
                        'assets/images/portfolio/project9/1.png',
                        'assets/images/portfolio/project9/2.png',
                        'assets/images/portfolio/project9/3.png',
                        'assets/images/portfolio/project9/4.png',
                        'assets/images/portfolio/project9/6.png'
                    
                      ],
                      details: 'Qessat Najah is a custom design wordpress website', 
                      },
                      { 
                        id: 10, 
                        name: 'The Writer Kefaya Khader' ,
                        country: 'Jordan',
                        subtitle: ' ',
                        website: '',
                        type: 'group1 group2 group3',
                        photo: 'assets/images/portfolio/thumb/3.png',
                        morephotos: [
                          'assets/images/portfolio/3.png',
                          'assets/images/portfolio/project10/1.png',
                          'assets/images/portfolio/project10/2.png',
                          'assets/images/portfolio/project10/3.png',
                          'assets/images/portfolio/project10/4.png'
                      
                        ],
                        details: 'Kefaya Khader website  is a custom blog design wordpress website', 
                        },
                        { 
                          id: 11, 
                          name: 'HR ALhosam for al haj' ,
                          country: 'KSA',
                          subtitle: 'HR system mangment to control employee for alhosam company ',
                          website: '',
                          type: 'group1 group2 group3',
                          photo: 'assets/images/portfolio/thumb/26.png',
                          morephotos: [
                            'assets/images/portfolio/26.png',
                            'assets/images/portfolio/project11/1.png',
                            'assets/images/portfolio/project11/2.png',
                            'assets/images/portfolio/project11/3.png',
                            'assets/images/portfolio/project11/4.png'
                        
                          ],
                          details: 'PHP Full Stack development , both frondend and backend coding .. Hr Mangment system', 
                          },
                          { 
                            id: 12, 
                            name: 'FoxSmart Solution' ,
                            country: 'Kuwait',
                            subtitle: ' ',
                            website: '',
                            type: 'group1 group2 group3',
                            photo: 'assets/images/portfolio/thumb/17.png',
                            morephotos: [
                              'assets/images/portfolio/17.png',
                              'assets/images/portfolio/project12/1.png',
                              'assets/images/portfolio/project12/2.png',
                              'assets/images/portfolio/project12/3.png',
                              'assets/images/portfolio/project12/4.png',
                          
                            ],
                            details: 'FoxSmart Solution is a custom design wordpress website', 
                            },
                            { 
                              id: 13, 
                              name: 'Reyadi Website' ,
                              country: 'Kuwait',
                              subtitle: ' ',
                              website: '',
                              type: 'group1  group3',
                              photo: 'assets/images/portfolio/thumb/9.png',
                              morephotos: [
                                'assets/images/portfolio/9.png',
                                'assets/images/portfolio/project13/1.png',
                                'assets/images/portfolio/project13/2.png',
                                'assets/images/portfolio/project13/3.png'

                            
                              ],
                              details: 'New wordpress Design for ryadi club website', 
                              },
                              { 
                                id: 14, 
                                name: 'Evoked Reflections',
                                country: 'Jordan',
                                subtitle: ' ',
                                website: 'http://evokedreflections.com/',
                                type: 'group1 group2 group3',
                                photo: 'assets/images/portfolio/thumb/27.png',
                                morephotos: [
                                  'assets/images/portfolio/27.png',
                                  'assets/images/portfolio/project14/5.png',
                                  'assets/images/portfolio/project14/1.png',
                                  'assets/images/portfolio/project14/2.png',
                                  'assets/images/portfolio/project14/3.png',
                                  'assets/images/portfolio/project14/4.png',
                                  'assets/images/portfolio/project14/6.png',
                                  'assets/images/portfolio/project14/7.png'
                              
                                ],
                                details: 'Evoked Reflections is a blog wordpress website', 
                                },
                                { 
                                  id: 15, 
                                  name: 'girls77 Group',
                                  country: 'UK',
                                  subtitle: ' ',
                                  website: '',
                                  type: 'group1  group3',
                                  photo: 'assets/images/portfolio/thumb/24.jpg',
                                  morephotos: [
                                    'assets/images/portfolio/24.jpg',
                                    'assets/images/portfolio/project15/2.png',
                                    'assets/images/portfolio/project15/1.png'                                
                                  ],
                                  details: 'Custom Web design for Magento website , girls77 Group website is a magento  online shop', 
                                  },
                                  { 
                                    id: 16, 
                                    name: 'Girl Shop',
                                    country: 'Jordan',
                                    subtitle: ' ',
                                    website: '',
                                    type: 'group1  group3',
                                    photo: 'assets/images/portfolio/thumb/2.png',
                                    morephotos: [
                                      'assets/images/portfolio/2.png',
                                                                  
                                    ],
                                    details: 'Custom Web design ', 
                                    },
                                    { 
                                      id: 17, 
                                      name: 'CookJo',
                                      country: 'Jordan',
                                      subtitle: ' ',
                                      website: '',
                                      type: 'group1  group3',
                                      photo: 'assets/images/portfolio/thumb/14.png',
                                      morephotos: [
                                        'assets/images/portfolio/14.png',
                                                                 
                                      ],
                                      details: 'Custom Web design ', 
                                      },
                                  { 
                                    id: 18, 
                                    name: 'Tawasol',
                                    country: 'Jordan',
                                    subtitle: '',
                                    website: '',
                                    type: 'group1 group2 group3',
                                    photo: 'assets/images/portfolio/thumb/23.png',
                                    morephotos: [
                                      'assets/images/portfolio/23.png',
                                      'assets/images/portfolio/project18/6.png',
                                      'assets/images/portfolio/project18/2.png',
                                      'assets/images/portfolio/project18/1.png',
                                      'assets/images/portfolio/project18/4.png',
                                      'assets/images/portfolio/project18/5.png',
                                      'assets/images/portfolio/project18/7.png',
                                      'assets/images/portfolio/project18/3.png'

                                
                                  
                                    ],
                                    details: 'Part of the team to develop tawasol website , full stack development fron and back end', 
                                    },
                                    { 
                                      id: 19, 
                                      name: 'gorgeousworkuk',
                                      country: 'UK',
                                      subtitle: ' ',
                                      website: '',
                                      type: 'group1  group3',
                                      photo: 'assets/images/portfolio/thumb/5.jpg',
                                      morephotos: [
                                        'assets/images/portfolio/5.jpg',
                                        'assets/images/portfolio/project19/3.png',
                                        'assets/images/portfolio/project19/1.png',
                                        'assets/images/portfolio/project19/2.png'
                                                                 
                                      ],
                                      details: 'New Web design for the uk website gorgeousworkuk ', 
                                      },
                                      { 
                                        id: 20, 
                                        name: 'Harmony Clinic',
                                        country: 'UK',
                                        subtitle: ' ',
                                        website: '',
                                        type: 'group1  group3',
                                        photo: 'assets/images/portfolio/thumb/10.png',
                                        morephotos: [
                                          'assets/images/portfolio/10.png',
                                                                   
                                        ],
                                        details: 'Custom Web design ', 
                                        },
                                        { 
                                          id: 21, 
                                          name: 'Saloon UK design',
                                          country: 'UK',
                                          subtitle: ' ',
                                          website: '',
                                          type: 'group1  group3',
                                          photo: 'assets/images/portfolio/thumb/11.jpg',
                                          morephotos: [
                                            'assets/images/portfolio/11.jpg',
                                                                     
                                          ],
                                          details: 'Custom Web design ', 
                                          },
                                          { 
                                            id: 22, 
                                            name: 'MG Company Design',
                                            country: 'UAE',
                                            subtitle: ' ',
                                            website: '',
                                            type: 'group1  group3',
                                            photo: 'assets/images/portfolio/thumb/12.png',
                                            morephotos: [
                                              'assets/images/portfolio/12.png',
                                              'assets/images/portfolio/project22/1.png',
                                                                       
                                            ],
                                            details: 'Custom Web design ', 
                                            },
                                            { 
                                              id: 23, 
                                              name: 'Proxy Design',
                                              country: 'UAE',
                                              subtitle: ' ',
                                              website: '',
                                              type: 'group1  group3',
                                              photo: 'assets/images/portfolio/thumb/15.png',
                                              morephotos: [
                                                'assets/images/portfolio/15.png',
                                                'assets/images/portfolio/project23/1.png',
                                                'assets/images/portfolio/project23/2.png',
                                                'assets/images/portfolio/project23/3.png',
                                                                         
                                              ],
                                              details: 'New design for free open proxy website ', 
                                              },
                                              { 
                                                id: 24, 
                                                name: 'Enasco Design',
                                                country: 'Kuwait',
                                                subtitle: ' ',
                                                website: '',
                                                type: 'group1  group3',
                                                photo: 'assets/images/portfolio/thumb/16.jpg',
                                                morephotos: [
                                                 'assets/images/portfolio/16.jpg',
                                                 'assets/images/portfolio/project24/1.jpg',
                                                 'assets/images/portfolio/project24/2.jpg',                                                                          
                                                ],
                                                details: 'New design Enasco Company ', 
                                                },
                                                { 
                                                  id: 25, 
                                                  name: 'Caddo Shop Design',
                                                  country: 'Jordan',
                                                  subtitle: ' ',
                                                  website: '',
                                                  type: 'group1  group3',
                                                  photo: 'assets/images/portfolio/thumb/28.png',
                                                  morephotos: [
                                                   'assets/images/portfolio/28.png',
                                                   'assets/images/portfolio/project25/1.png',
                                                  ],
                                                  details: 'New design Enasco Company ', 
                                                  },
  ];
  
  constructor() {

   }
  get_detils(event,obj){
    $('#blackbg').show(100);
    $('.project_detils #d_title').html(obj.name);
    $('.project_detils #d_country').html(obj.country);
    $('.project_detils #d_subtitle').html(obj.subtitle);
    $('.project_detils #d_details').html(obj.details);
    if(obj.website != ''){
      var websitelink = '<a target="_blank" href="'+ obj.website +'">Click Here to vist the website</a>';
      $('.project_detils #d_link').html(websitelink);
    }else{
      $('.project_detils #d_link').html('');
    }
    var imgs = '';
    var i = 1;
    $.each( obj.morephotos, function( i, val ) {
      imgs += '<div id="myImg" style=" border-radius: 5px;cursor: pointer;transition: 0.3s;margin:15px 7px;" class="item_popup popup" alt="'+ obj.name +'" ><img src="'+val+'"></div>';
    });
    $('.project_detils .masonry_popup').html(imgs);
    $('.project_detils ').addClass('item_open');  
  
  
  
  };


  ngOnInit() {
  $(function() {
    var j$ = $,
          $mContainer = j$("#mnsry_container"),
          $filterButton = j$("#mnsry_container .button"),
          $loadingMessage = j$("#mnsry_container #loading_msg");
          var params = {
            itemSelector: "#mnsry_container .item",
            filtersGroupSelector:".filters"
          };
        // After the page is loaded
        j$(window).on('load',function() { 
        // Do mansonry with filtering 
        $mContainer.multipleFilterMasonry(params);
        // Show articles with fadein
        $mContainer.find("article").animate({
            "opacity":1
          }, 1200);
        // Hide loading message
        $loadingMessage.fadeOut();
      
        // Change the filtering button(label) status 
        $filterButton.find("input").change(function(){
          j$(this).parent().toggleClass("active");
        });
      });
      
      
       // portfolio

      /* $('.articlephoto').on('click',function(event){
         console.log(event.target);
          $('#blackbg').show(100);
          var itemID = $(this).data('link');
          console.log(itemID);
          console.log(this.portfilio_list[1]);
          var obj =  this.portfilio_list[itemID -1];
          $('.project_detils #d_title').html(obj.name);
          $('.project_detils #d_country').html(obj.country);
          $('.project_detils #d_subtitle').html(obj.subtitle);
          $('.project_detils #d_details').html(obj.details);
          if(obj.website != ''){
            var websitelink = '<a target="_blank" href="'+ obj.website +'">Click Here to vist the website</a>';
            $('.project_detils #d_link').html(websitelink);
          }
          var imgs = '';
          var i = 1;
          $.each( obj.morephotos, function( i, val ) {
            imgs += '<div id="myImg" class="item_popup popup" alt="'+ obj.title +'" ><img src="'+val+'"></div>';
          });
          $('.project_detils .masonry_popup').html(imgs);
          $('.project_detils ').addClass('item_open');    
    });
    */
       $('.port > .close').click(function() {
           $('.port').removeClass('item_open');
           $('#blackbg').hide(100);
       });
      
      
      
       $('#blackbg').click(function() {
        $('.port').removeClass('item_open');
        $('#blackbg').hide(100);
    
    });
      $('.project_detils').on( "click", ".popup", function() {
            $('#myModal').show();
            var src = $(this).find('img').attr('src');
            $('#img01').attr('src' , src);
            $('#caption').html(this.alt);
      });
      
      
      $('#myModal').on( "click", ".close", function() { 
        $('#myModal').hide();
      }); 
      
      
      });  
    

  }

}
