import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PphComponent } from './pph/pph.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LifeeventComponent } from './lifeevent/lifeevent.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SkillsComponent } from './skills/skills.component';

declare var jquery:any;
declare var $ :any;
@NgModule({
  declarations: [
    AppComponent,
    PortfolioComponent,
    PphComponent,
    TimelineComponent,
    LifeeventComponent,
    SkillsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScrollToModule.forRoot()

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
