import { Component, OnInit } from '@angular/core';
import {TweenLite} from "gsap";
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-lifeevent',
  templateUrl: './lifeevent.component.html',
  styleUrls: ['./lifeevent.component.less']
})
export class LifeeventComponent implements OnInit {

    events = [
      {
        id : 1,
        event_date : 1991,
        icon: '',
        event_title: "",
        event_d : "",
        event: 'false'
      },
      {
        id : 3,
        event_date : 1993,
        icon: 'fa-child',
        event_title: "Hey..I Just come to life..",
        event_d : "Hey..I Just come to life..Today is my first day here on your earth, Don't know anything at the moment, I am just crying hard because I want to be a Software Engineer when I grow up, can't wait to get my first laptop and start coding!",
        event: 'active'
      },
      {
        id : 4,
        event_date : 2011,
        icon: 'fa-graduation-cap',
        event_title: "92.5% is my GPA ..",
        event_d :"Finally, I did it, and I finished the General Certificate of Secondary Education/Scientific Stream, with a 92.5%, what's really matters here is that I got 98% in Computer Class :D , Going to make a party now,,Bye ! ",
        event: 'false'
      },
      {
        id : 5,
        event_date : 2012,
        icon: 'fa-lightbulb-o',
        event_title: "I felt I had to do something, ",
        event_d : "After 1 year of university! .. And here I am, on the first day of my training.. In a software company close to my university.. I used to train after the end of my class for about 3h per day .. Don't worry! It's for free! Not a paid job at all :D ..  ",
        event: 'false'
      },

      {
        id : 6,
        event_date : 2013,
        icon: 'fa-graduation-cap',
        event_title: "Start Working as a freelancer Developer ..",
        event_d :"It was the Kickoff of my career life. I created accounts at remote work sites like (Upwork, PeoplePerHour), and started the first project, 7 days of working with a budget of $20 only, NOW: I've 51 Jobs Done! With 100% Rating ..",
        event: 'false'
      },
      {
        id : 8,
        event_date : 2015,
        icon: 'fa-briefcase',
        event_title: "My first paid job !..",
        event_d :" Today I'm totally excited.. After 2 years of training and learning web development.. I got my first paid job.. a remote job for a KSA Company called MPGLine! Position of a web developer, I have a lot to do and I have exams at the university..So busy..bye bye",
        event: 'false'
      },
      {
        id : 9,
        event_date : 2016,
        icon: 'fa-graduation-cap',
        event_title: "Iam so happy today..",
        event_d :" After 5 years of Hard Study, Work, and training.. I won one small piece of paper! Written on it: Bachelor's degree In Engineering Technology/Computer Engineer, Anyway, my picture with this cute paper looks fantastic ..",
        event: 'false'
      },
      {
        id : 11,
        event_date : 2018,
        icon: 'fa-briefcase',
        event_title: "MetaData - UAE/Abu Dhabi.. ",
        event_d : "I got the chance to be in Metadata company - UAE, Postion of full stack web developer, 2 years full of good experience..",
        event: 'false'
      },

      {
        id : 11,
        event_date : 2020,
        icon: 'fa-briefcase',
        event_title: "Department Of Economic Development - UAE/Abu Dhabi.. ",
        event_d : "Currently, I'm a web developer at DED/Abu Dhabi seting and waiting to see what this life is gonig to offer me next!",
        event: 'false'
      },
    ]
  

       
  constructor() { }

  ngOnInit() {

  }
}

  


$(document).ready(function(e) {
  var viewport =Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
 // console.log(viewport);
  $('#lifeevents a').click(function(e){
    e.preventDefault()
  })
  if ($(window).width() < 600) {
    //alert('Less than 600');
    $('.goal_wrap').click(function(){
      var diff = $(this).parent()[0].offsetLeft;
  
      var dd = $(this).parent().parent().parent().find('.showclass');
      $(dd).removeClass('showclass');
      var link1 =$(this).data('link');
      console.log(link1);

      $(link1).addClass('showclass');
  
      $('.date .goal_wrap').removeClass('active bounce');
      $(this).addClass('active bounce');
      console.log(diff);
      console.log((viewport - diff));
      TweenLite.to($('#lifeevents .date').parent(), 1, {x:((viewport*0.2) - diff), onComplete:function(){
         // console.log('success');
          /*TweenLite.to($('.timeline'), 1, {top:"50%"});*/
        }});  
    });
 }
 else {
   // alert('More than 600');
    $('.goal_wrap').click(function(){
      var diff = $(this).parent()[0].offsetLeft;
  
      var dd = $(this).parent().parent().find('.showclass');
      $(dd).removeClass('showclass');
      $(this).find('#descrip').addClass('showclass');
  
      $('.date .goal_wrap').removeClass('active bounce');
      $(this).addClass('active bounce');
      console.log(diff);
      console.log((viewport - diff));
      TweenLite.to($('#lifeevents .date').parent(), 1, {x:((viewport*0.5) - diff), onComplete:function(){
          console.log('success');
          /*TweenLite.to($('.timeline'), 1, {top:"50%"});*/
        }});  
    });
 }

  

  
/*
$('.goal_baby').click(function(){
    console.log('goal click');
$('#lifeevents').fadeTo('ease', 0.3, function()
{
    $(this).css('background-color', 'white');
}).fadeTo('slow', 1);

});  
  
$('.goal_real_tawjehi').click(function(){
    console.log('goal click');
$('#lifeevents').fadeTo('ease', 0.3, function()
{
    $(this).css('background-color', '#26bfb5');
}).fadeTo('slow', 1);

  });
     $('.goal_retirement').click(function(){
    console.log('goal click');
$('#lifeevents').fadeTo('ease', 0.3, function()
{
    $(this).css('background-color', 'white');
}).fadeTo('slow', 1);

  });  
$('.goal_uni').click(function(){
    console.log('goal click');
$('#lifeevents').fadeTo('ease', 0.3, function()
{
    $(this).css('background-color', 'white');
}).fadeTo('slow', 1);

  });*/
  

});
